import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import { LegalContactsPolarisFactory } from "./polaris/legal-contacts-polaris-factory";
import {
    ColumnDisplayPreference,
    DisplayConfiguration,
    LegalContact,
    WhosMyLawyerContent
} from "@amzn/ask-legal-domain";
import { CollectionPreferencesProps, TableProps } from "@amzn/awsui-components-react";
import { Preference } from "../setup/preference";

export namespace WhosMyLawyerFactory {
    export const toOptionDefinition = (id: string) => {
        const definition = getSortSelectOptions().find(x => x.value === id);
        if (!definition) {
            return toOptionDefinition("legalContactUser.name");
        }
        return definition;
    };

    export function getSortSelectOptions(): OptionDefinition[] {
        return LegalContactsPolarisFactory.Table.toColumnDefinition().filter(y => y.sortingField).map(x => ({
            label: x.header,
            value: x.sortingField
        }));
    }

    export function getSortingStateFromContent(content: WhosMyLawyerContent): TableProps.SortingState<LegalContact.Record> {
        if (!content.defaultConfiguration.sorting) {
            return LegalContactsPolarisFactory.DEFAULT_SORTING_STATE;
        }
        return {
            sortingColumn: LegalContactsPolarisFactory.Table.getSortingColumnById(content.defaultConfiguration.sorting.field),
            isDescending: content.defaultConfiguration.sorting.order === "DESC"
        };
    }

    export function toPreferencesFromDisplayConfiguration(
        configuration: DisplayConfiguration,
        labs?: Preference.Lab[]
    ): CollectionPreferencesProps.Preferences<LegalContact.Record> {
        if (!configuration) return LegalContactsPolarisFactory.DEFAULT_PREFERENCES(labs);
        const contentDisplay = configuration.columnDisplay?.map(col => {
            if (labs.includes(Preference.Lab.WhosMyLegalContactOptionsPhase1)) {
                if (col.id === "legalScope") {
                    return { ...col, id: "legalScopes" };
                }
            }
            if (labs.includes(Preference.Lab.WhosMyLegalContactOptionsPhase2)) {
                if (col.id === "legalTeam") {
                    return { ...col, id: "legalTeams" };
                }
                if (col.id === "legalSubTeam") {
                    return { ...col, id: "legalSubTeams" };
                }
            }
            return col;
        }) || LegalContactsPolarisFactory.DEFAULT_PREFERENCES(labs).contentDisplay;
        return {
            pageSize: configuration.pageSize || LegalContactsPolarisFactory.DEFAULT_PREFERENCES(labs).pageSize,
            contentDisplay: contentDisplay,
            stripedRows: configuration.stripedRows || LegalContactsPolarisFactory.DEFAULT_PREFERENCES(labs).stripedRows,
        };
    }

    export function toDisplayConfigurationFromPreferences(
        prefs: CollectionPreferencesProps.Preferences<LegalContact.Record>,
        configuration?: DisplayConfiguration
    ): DisplayConfiguration {
        return {
            ...configuration,
            pageSize: prefs.pageSize,
            columnDisplay: prefs.contentDisplay as ColumnDisplayPreference[],
            stripedRows: prefs.stripedRows
        };
    }
}