import { LoadAdvancedListCommandInput, LoadAdvancedListCommandOutput } from "@amzn/altar-sds-client";
import { APIResponse, toDefaultRequestPayload } from "./common";
import { AppContextValue } from "../setup/context-value";
import { RestAPI } from "@aws-amplify/api-rest";

export interface AdvancedListAPI {
    load(input: LoadAdvancedListCommandInput): Promise<APIResponse<LoadAdvancedListCommandOutput>>;
}

export class AdvancedListAPIImpl implements AdvancedListAPI {
    constructor (private context: AppContextValue) {}

    async load(input: LoadAdvancedListCommandInput): Promise<APIResponse<LoadAdvancedListCommandOutput>> {
        const response: APIResponse<LoadAdvancedListCommandOutput> = await RestAPI.post(
            "custom-domain",
            "/advanced-list/load",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}