import { ContainerRef, AdvancedListContent, Identity } from "@amzn/ask-legal-domain";
import * as React from "react";
import { Box, Spinner, Table } from "@amzn/awsui-components-react";
import { AppContext } from "../../../setup/context";
import { useAPI2 } from "../../../hooks/api-hook";
import { AdvancedList } from "@amzn/altar-sds-client";

export const AdvancedListContentView = (props: {
    content: AdvancedListContent;
    containerRef: ContainerRef;
    showEditControls?: boolean;
}) => {
    const context = React.useContext(AppContext);
    const loadAdvancedListRunner = useAPI2(
        context.getAdvancedListAPI().load
    );
    const [advancedList, setAdvancedList] = React.useState<AdvancedList>();
    const [userIdentity, setUserIdentity] = React.useState<Identity>(null);
    const isLive = window.location.href.includes("/live");

    React.useEffect(() => {
        if (props.containerRef.version === 1) {
            return;
        } else if (userIdentity?.id) {
            loadAdvancedListRunner.invoke({
                entityId: props.content?.entityRef?.entityId,
                repositoryId: props.content?.entityRef?.repositoryRef.repositoryId,
                by: {
                    id: userIdentity.id,
                    realm: "Amazon",
                    type: "Person"
                }
            });
        }
    }, [props.content?.entityRef?.entityId, userIdentity]);

    const fetchIdentity = async () => {
        let currentUser: Identity;
        try {
            currentUser = await context.getIdentity();
        } catch (err) {
            console.warn(err);
        }
        if (!currentUser) {
            console.warn("Error fetching user");
            setUserIdentity(null);
        }
        setUserIdentity(currentUser);
    };

    React.useEffect(() => {
        if (loadAdvancedListRunner.output) {
            setAdvancedList(loadAdvancedListRunner.output.body);
        }
    }, [loadAdvancedListRunner.status]);

    React.useEffect(() => {
        fetchIdentity();
    }, []);

    return (
        <>
            <h2>{props.content?.updateAdvancedListPayload?.name || advancedList?.name}</h2>
            <Box>{props.content?.updateAdvancedListPayload?.description || advancedList?.description}</Box>
            <br/>
            {loadAdvancedListRunner.status === "Running" && (<Spinner/>)}
            <Table
                items={[]}
                columnDefinitions={[
                    ...(advancedList ? advancedList?.fieldDefinitions?.map(f => ({
                        header: f.displayName,
                        id: f.displayName,
                        cell: item => <>TODO</>
                    })) : []),
                    ...(!isLive ? (props.content?.updateAdvancedListPayload?.addFieldDefinitionsList || []) : []).map(f => ({
                        header: `${f.displayName} (pending)`,
                        id: f.displayName,
                        cell: item => <>TODO</>
                    })),
                ]}
                empty="No Items have been created yet"
            />
        </>
    );
};