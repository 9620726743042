import * as React from "react";
import {
    Box,
    Button,
    Header,
    SpaceBetween,
    Spinner,
    Tabs
} from "@amzn/awsui-components-react";
import { InstanceOverviewTab } from "../../components/instance/InstanceOverview";
import { PageLibraryHome } from "../../components/pageLibrary/PageLibraryHome";
import { AppContext } from "../../setup/context";
import { useAPI2 } from "../../hooks/api-hook";
import { UIModel } from "../../model/ui-model";
import {
    AppError,
    EntityFactory,
    EntityPermissionOperationNames,
    EntityType,
    IEntityPermission,
    Instance
} from "@amzn/ask-legal-domain";
import { EntityPermissionDetail } from "../../components/common/EntityPermissionComp";
import { PageDraftTable } from "../../components/page-draft/PageDraftsTable";
import { NavigationPreload } from "../../components/navigation/NavigationPreload";
import { RecommendationTable } from "../../components/recommendation/RecommendationTable";
import { TeamDefinitionEdit } from "../../components/instance/TeamDefinitionEdit";
import { RoleTable } from "../../components/role/RoleTable";
import { DescriptionManagerHome } from "../../components/descriptionManager/DescriptionManagerHome";
import { InstancePreferences } from "../../components/instance/InstancePreferences";
import { LabelTable } from "../../components/label/LabelTable";
import { PageNotFound } from "../redirects/PageNotFound";
import { PermissionDenied } from "../redirects/PermissionDenied";
import { ErrorFlashbar } from "../../components/common/ErrorFlashbar";
import { AllLegalContactsTable } from "../../components/legal-contact/AllLegalContactsTable";
import { Preference } from "../../setup/preference";

const Comp = (props: {
    instanceId: string;
    viewOnly?: boolean;
}) => {
    const context = React.useContext(AppContext);
    const loadInstanceRunner = useAPI2(
        context.getInstanceAPI().load
    );
    const instanceDataState = UIModel.State.use<Instance>({});
    const [activeTabId, setActiveTabId] = React.useState("overview");

    React.useEffect(() => {
        loadInstanceRunner.invoke(props.instanceId);
    }, [props.instanceId]);

    React.useEffect(() => {
        if (loadInstanceRunner.status === "Succeeded") {
            instanceDataState.setValue(loadInstanceRunner.output);
        }
    }, [loadInstanceRunner.status]);

    const getContent = () => {
        const adminTabs = [
            {
                label: "Overview",
                id: "overview",
                content: (
                    <SpaceBetween size="m">
                        <InstanceOverviewTab state={instanceDataState} viewOnly={props.viewOnly}/>
                    </SpaceBetween>)
            },
            {
                label: "Preferences",
                id: "preferences",
                content: <InstancePreferences.Main state={instanceDataState} />
            },
            {
                label: "Recommendations",
                id: "recommendations",
                content: (
                    <React.Fragment>
                        <SpaceBetween size="m">
                            <Button variant="primary" href={`#/recommendation/${props.instanceId}/admin`}>
                                Recommendation management portal
                            </Button>
                            <RecommendationTable id={props.instanceId} viewOnly={true} />
                        </SpaceBetween>
                    </React.Fragment>
                )
            },
            {
                label: "Permissions",
                id: "permissions",
                content: (
                    <SpaceBetween direction="vertical" size="xl">
                        <EntityPermissionDetail.PreloadComp id={props.instanceId} viewOnly={props.viewOnly} />
                        {!props.viewOnly && <TeamDefinitionEdit state={instanceDataState} />}
                    </SpaceBetween>
                )
            },
            {
                label: "Navigation",
                id: "navigation",
                content: <NavigationPreload instanceId={props.instanceId} />
            },
            {
                label: "Roles",
                id: "roles",
                content: <RoleTable id={props.instanceId} />
            },
            {
                label: "All Drafts",
                id: "allDrafts",
                content: <PageDraftTable
                    entityRef={EntityFactory.toEntityRef(instanceDataState.value)}
                />
            },
            {
                label: "Page Library",
                id: "pageLibrary",
                content: <PageLibraryHome id={props.instanceId} viewOnly={props.viewOnly} />
            },
            {
                label: "Description Manager",
                id: "descriptionManager",
                content: <DescriptionManagerHome id={props.instanceId} />
            },
            {
                label: "Label Manager",
                id: "labelManager",
                content: <LabelTable id={props.instanceId} />
            }
        ];
        if (context.hasLab(Preference.Lab.WhosMyLegalContactFeature)) {
            adminTabs.push({
                label: "Legal Contacts",
                id: "legalContacts",
                content: <AllLegalContactsTable instanceId={props.instanceId} />
            });
        }
        const viewTabs = adminTabs.filter(t => ["overview", "permissions", "pageLibrary"].includes(t.id));
        return (
            <Tabs
                tabs={props.viewOnly ? viewTabs : adminTabs}
                activeTabId={activeTabId}
                onChange={(e) => setActiveTabId(e.detail.activeTabId)}
            />
        );
    };

    return (
        <>
            <Header id="pageHeader" variant="h1">Admin Portal</Header>
            {loadInstanceRunner.status === "Running" &&
                <Box textAlign="center" className="awsui-util-status-inactive">
                    <Spinner /> Loading Instance Details
                </Box>
            }
            {loadInstanceRunner.status === "Error" && <PageNotFound />}
            {loadInstanceRunner.status === "Succeeded" && !!instanceDataState.value &&
                getContent()
            }
        </>
    );
};

export const InstancePortal = (props: {
    instanceId: string;
}) => {
    const context = React.useContext(AppContext);
    const [viewType, setViewType] = React.useState<"View" | "Admin" | "Unauthorized" | "Error" | null>(null);

    const isViewAuthorizedRunner = useAPI2(
        context.getEntityPermissionAPI().isAuthorized
    );
    const isAdminAuthorizedRunner = useAPI2(
        context.getEntityPermissionAPI().isAuthorized
    );

    React.useEffect(() => {
        isAdminAuthorizedRunner.invoke(
            IEntityPermission.IsAuthorizedInput.create(
                props.instanceId,
                EntityType.Instance,
                EntityPermissionOperationNames.CAN_ADMIN
            )
        );
        isViewAuthorizedRunner.invoke(
            IEntityPermission.IsAuthorizedInput.create(
                props.instanceId,
                EntityType.Instance,
                EntityPermissionOperationNames.CAN_VIEW
            )
        );

    }, [props.instanceId]);

    React.useEffect(() => {
        if (isAdminAuthorizedRunner.status === "Succeeded" && isAdminAuthorizedRunner.output) {
            if (viewType !== "Admin") {
                setViewType("Admin");
            }
        } else if (isAdminAuthorizedRunner.status === "Succeeded") {
            if (isViewAuthorizedRunner.status === "Succeeded") {
                if (isViewAuthorizedRunner.output) {
                    setViewType("View");
                } else {
                    setViewType("Unauthorized");
                }
            }
        } else if (isViewAuthorizedRunner.status === "Error" || isAdminAuthorizedRunner.status === "Error") {
            setViewType("Error");
        }
    }, [isViewAuthorizedRunner.status, isAdminAuthorizedRunner.status]);

    return (
        <>
            {!viewType &&
                <Box textAlign="center" className="awsui-util-status-inactive">
                    <Spinner size="big" /> Checking permissions
                </Box>
            }
            {viewType === "Error" && <ErrorFlashbar error={AppError.create("An unexpected error occured, please reload page", 500)} />}
            {viewType === "Unauthorized" &&
                <PermissionDenied entityId={props.instanceId} accessType="InstanceAdminPortal" />
            }
            {["View", "Admin"].includes(viewType) &&
                <Comp instanceId={props.instanceId} viewOnly={viewType === "View"} />
            }
        </>
    );
};