import {
    Box,
    Button,
    ColumnLayout,
    FormField,
    Grid,
    Link,
    Multiselect,
    Popover,
    Select,
    SelectProps,
    SpaceBetween,
    TokenGroup
} from "@amzn/awsui-components-react";
import * as React from "react";
import { UIField } from "../common/UIField";
import { UserSearch } from "../common/UserSearch";
import { LegalContactModel } from "../../model/legal-contact-model";
import { InputTokens } from "../common/InputTokens";
import { ConfirmLeavePageModal } from "../common/ConfirmLeavePageModal";
import {
    ConfigurableOption,
    PrimitiveDataFactory,
    WhosMyLawyerContent,
    WhosMyLawyerContentFactory
} from "@amzn/ask-legal-domain";
import { AppContext } from "../../setup/context";
import { useAPI } from "../../hooks/api-hook";
import { Preference } from "../../setup/preference";
import { Input } from "@amzn/awsui-components-react-v3";
import { UIModel } from "../../model/ui-model";

export const EditLegalContactForm = (props: {
    state: LegalContactModel.UpdateState
}) => {
    const [scopeOptions, setScopeOptions] = React.useState<ConfigurableOption[]>([]);
    const [teamOptions, setTeamOptions] = React.useState<ConfigurableOption[]>([]);
    const [subTeamOptions, setSubTeamOptions] = React.useState<ConfigurableOption[]>([]);
    const context = React.useContext(AppContext);
    const loadContainerRunner = useAPI(
        context.getContainerAPI().loadVersion
    );

    const toConfigurableOption = (selectOption: SelectProps.Option): ConfigurableOption => {
        return {
            id: selectOption.value,
            displayValue: selectOption.label
        };
    };
    const fromConfigurableOption = (option: ConfigurableOption): SelectProps.Option => {
        return {
            value: option.id,
            label: option.displayValue || option.id
        };
    };

    React.useEffect(() => {
        loadContainerRunner.submitRun(
            props.state.containerRef
        );
    }, [props.state?.containerRef]);

    React.useEffect(() => {
        if (loadContainerRunner.status === "Succeeded") {
            setScopeOptions(
                (loadContainerRunner.data.output.loadedContent as WhosMyLawyerContent).dataOptions?.scopeOptions || []
            );
            setTeamOptions(
                (loadContainerRunner.data.output.loadedContent as WhosMyLawyerContent).dataOptions?.teamOptions || []
            );
            setSubTeamOptions(
                (loadContainerRunner.data.output.loadedContent as WhosMyLawyerContent).dataOptions?.subTeamOptions || []
            );
        }
    }, [loadContainerRunner.status]);

    return (
        <>
            <ConfirmLeavePageModal showModal={true} />
            <ColumnLayout columns={2}>
                <div style={{ maxWidth: "650px" }}>
                    <UIField.CustomField
                        name="Legal Contact"
                        errorText={props.state.legalContactUser.errorText}
                        child={
                            <UserSearch.Single
                                selected={props.state.legalContactUser.value}
                                onUserSelectChange={(selected) => props.state.legalContactUser.setValue(selected)}
                            />
                        }
                    />
                </div>
                <div style={{ maxWidth: "650px" }}>
                    <UIField.CustomField
                        name="Business Leader(s)"
                        helpInfo={<Popover
                            dismissAriaLabel="Close"
                            header="Legal Contact's Business Leaders"
                            triggerType="custom"
                            content={<Box color="text-body-secondary">
                                Select the L8 and/or L10 leaders of the organizations for which they are a primary business line legal contact.
                                <br /><br />
                                Business partners using this tool will receive recommendations on which legal contact to reach out to based on the leaders entered here.
                            </Box>}
                        >
                            <Link variant="info">Need Help?</Link>
                        </Popover>}
                        errorText={props.state.businessLeader.errorText}
                        child={
                            <UserSearch.Multiple
                                initialSelected={props.state.businessLeader.value}
                                onUserSelectChange={(selected) => props.state.businessLeader.setValue(selected)}
                            />
                        }
                    />
                </div>
                {!context.hasLab(Preference.Lab.WhosMyLegalContactOptionsPhase2) && <UIField.StateValueField
                    name="Legal Team"
                    state={props.state.team}
                    editing
                />}
                {context.hasLab(Preference.Lab.WhosMyLegalContactOptionsPhase2) && <UIField.CustomField
                    name="Legal Team"
                    errorText={props.state.teamOptions?.errorText}
                    child={
                        <Select
                            selectedOption={props.state.teamOptions?.value?.map((option) => ({
                                value: teamOptions.find(s => s.id === option.id)?.id,
                                label: teamOptions.find(s => s.id === option.id)?.displayValue || option.id
                            }))[0]}
                            disabled={loadContainerRunner.status === "Running"}
                            statusType={loadContainerRunner.status === "Running" && "loading"}
                            loadingText="Loading..."
                            options={teamOptions
                                .filter(o => !o.deprecated)
                                .map(o => fromConfigurableOption(o))
                            }
                            onChange={(d) => {
                                props.state.teamOptions.setValue(
                                    [toConfigurableOption(d.detail.selectedOption)]
                                );
                            }}
                            filteringType="auto"
                            placeholder="Choose a team"
                            empty="No teams found"
                        />
                    }
                />}
                {!context.hasLab(Preference.Lab.WhosMyLegalContactOptionsPhase2) && <UIField.CustomField
                    name="Legal Sub-Team"
                    errorText={props.state.subTeam.errorText}
                    child={
                        <InputTokens
                            values={props.state.subTeam.value}
                            setValues={props.state.subTeam.setValue}
                        />
                    }
                />}
                {context.hasLab(Preference.Lab.WhosMyLegalContactOptionsPhase2) && <UIField.CustomField
                    name="Legal Sub-Team"
                    errorText={props.state.subTeamOptions?.errorText}
                    child={
                        <Multiselect
                            selectedOptions={props.state.subTeamOptions?.value?.map((option) => ({
                                value: subTeamOptions.find(s => s.id === option.id)?.id,
                                label: subTeamOptions.find(s => s.id === option.id)?.displayValue || option.id
                            }))}
                            disabled={loadContainerRunner.status === "Running"}
                            statusType={loadContainerRunner.status === "Running" && "loading"}
                            loadingText="Loading..."
                            options={subTeamOptions
                                .filter(o => !o.deprecated)
                                .map(o => fromConfigurableOption(o))
                            }
                            onChange={(d) => {
                                props.state.subTeamOptions.setValue(
                                    d.detail.selectedOptions.map(x => toConfigurableOption(x))
                                );
                            }}
                            filteringType="auto"
                            placeholder="Choose a sub-team"
                            empty="No sub-teams found"
                        />
                    }
                />}
                {!context.hasLab(Preference.Lab.WhosMyLegalContactOptionsPhase1) && <UIField.StateValueField
                    name="Scope"
                    state={props.state.scope}
                    helpInfo={<Popover
                        dismissAriaLabel="Close"
                        header="Legal Contact's Scope"
                        triggerType="custom"
                        content={<Box color="text-body-secondary">
                            Briefly describe the scope of their role, including specialized subject matter expertise, custom coverage details, and geography covered.
                            <br /><br />
                            (e.g., <em>Import Controls and Customs - EMEA, AWS Snow products, etc.</em>)
                        </Box>}
                    >
                        <Link variant="info">Need Help?</Link>
                    </Popover>}
                    variant="TextArea"
                    editing
                />}
                {context.hasLab(Preference.Lab.WhosMyLegalContactOptionsPhase1) && (<>
                    <UIField.CustomField
                        name="Legal Scope"
                        errorText={props.state.scopeOptions?.errorText}
                        child={
                            <ConfigurableOptionMultiselectWithFreeText
                                configurableOptionsEditState={props.state.scopeOptions}
                                options={scopeOptions}
                                loadingOptions={loadContainerRunner.status === "Running"}
                            />
                        }
                    />
                </>)}
            </ColumnLayout>
        </>
    );
};

const ConfigurableOptionMultiselectWithFreeText = (props: {
    configurableOptionsEditState: UIModel.State<ConfigurableOption[]>,
    options: ConfigurableOption[],
    loadingOptions: boolean
}) => {
    const [adhocMode, setAdhocMode] = React.useState(false);
    const addOptionState = UIModel.State.use<string>({
        initialValue: "",
        validation: (t: string) => {
            if (t.length > WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_CHAR_LIMIT) {
                return `Length must not exceed ${WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_CHAR_LIMIT}`;
            }
            if (props.configurableOptionsEditState?.value?.find(o => o.displayValue === t)) {
                return "Option must be unique";
            }
        }
    });

    const onItemAdd = () => {
        const configOption: ConfigurableOption = {
            id: `${WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_ADHOC_PREFIX}${PrimitiveDataFactory.id()}`,
            displayValue: addOptionState.value.trim(),
            deprecated: false
        };
        props.configurableOptionsEditState.setValue(
            [...props.configurableOptionsEditState.value, configOption]
        );
        addOptionState.setValue("");
        setAdhocMode(false);
    };

    return <>
        {adhocMode ? (
            <SpaceBetween size="s">
                <FormField errorText={addOptionState.errorText}>
                    <Grid
                        gridDefinition={[
                            { colspan: 10 },
                            { colspan: 2 }
                        ]}
                    >
                        <Input
                            placeholder={"Enter another option"}
                            value={addOptionState.value}
                            autoFocus
                            onChange={({ detail }) => addOptionState.setValue(detail.value)}
                            onKeyDown={({ detail }) => {
                                if (detail.keyCode === 13) {
                                    onItemAdd();
                                }
                            }}
                        />
                        <SpaceBetween size="s" direction="horizontal">
                            <Button
                                variant="icon"
                                iconName="close"
                                onClick={() => {
                                    setAdhocMode(false);
                                    addOptionState.setValue("");
                                }}
                            />
                            <Button
                                disabled={
                                    props.options?.find(o => o.displayValue === addOptionState.value) ||
                                    addOptionState.value.length > WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_CHAR_LIMIT
                                }
                                variant="icon"
                                iconName="add-plus"
                                onClick={onItemAdd}
                            />
                        </SpaceBetween>
                    </Grid>
                </FormField>
            </SpaceBetween>
        ) : (
            <Multiselect
                selectedOptions={props.configurableOptionsEditState?.value
                    ?.filter(o => !o.id.includes(WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_ADHOC_PREFIX))
                    .map((o) => {
                        return {
                            value: props.options.find(s => s.id === o.id)?.id,
                            label: props.options.find(s => s.id === o.id)?.displayValue
                        };
                    })
                }
                disabled={props.loadingOptions}
                statusType={props.loadingOptions && "loading"}
                loadingText="Loading..."
                options={
                    [
                        ...props.options
                            .filter(o => !o.deprecated)
                            .map(o => ({label: o.displayValue, value: o.id})),
                        {label: "Other", value: "other", description: "Add your own scope"}
                    ]
                }
                onChange={(d) => {
                    if (d.detail.selectedOptions.find(o => o.value === "other")) {
                        setAdhocMode(true);
                    } else {
                        props.configurableOptionsEditState.setValue([
                            ...d.detail.selectedOptions.map(o => ({displayValue: o.label, id: o.value})),
                            ...props.configurableOptionsEditState?.value?.filter(v => v.id.includes(WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_ADHOC_PREFIX))
                        ]);
                    }
                }}
                hideTokens={true}
                filteringType="auto"
                placeholder="Choose all applicable scope options"
                empty="No scopes found"
            />
        )}
        <TokenGroup
            items={props.configurableOptionsEditState?.value?.map((o) => {
                return {
                    value: o.id,
                    label: o.displayValue
                };
            })}
            onDismiss={({ detail: { itemIndex } }) => {
                props.configurableOptionsEditState?.setValue([
                    ...props.configurableOptionsEditState?.value?.slice(0, itemIndex),
                    ...props.configurableOptionsEditState?.value?.slice(itemIndex + 1)
                ]);
            }}
        />
    </>;
};